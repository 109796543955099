import React from 'react';
import Layout from '../components/layout';
import { navigate } from 'gatsby';
import TopoS3 from '../utils/TopoS3';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    if (!window) {
      return;
    }

    if (typeof window !== 'undefined' && window.localStorage.getItem('map')) {
      this.setState(JSON.parse(window.localStorage.getItem('map')));
    }

    const stripe = window.Stripe('pk_test_Yy7on5HdiVKEI5GzAC854AEf006M2t3UJY');

    var elements = stripe.elements({
      locale: window.__exampleLocale,
    });

    var card = elements.create('card', {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: '#777',
          color: '#777',
          '::placeholder': {
            color: '#cccccc',
          },
        },
        invalid: {
          iconColor: 'red',
          color: 'red',
        },
      },
    });
    card.mount('#payment-card');

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async event => {
      event.preventDefault();

      // TODO: ugg, this sucks, maybe assign localstorage to a variable?
      if (typeof window !== 'undefined' && window.localStorage.getItem('map')) {
        let map = window.localStorage.getItem('map');
        new TopoS3().upload(JSON.parse(map));
      }

      const { token, error } = await stripe.createToken(card, {
        name: document.getElementById('payment-form-name').value,
        address_line1: document.getElementById('payment-form-address').value,
        address_city: document.getElementById('payment-form-city').value,
        address_state: document.getElementById('payment-form-state').value,
        address_zip: document.getElementById('payment-form-zip').value,
      });
      if (error) {
        const errorElement = document.getElementById('card-errors');
        errorElement.textContent = error.message;
      } else {
        let body = {
          token: token.id,
          mapId: document.getElementById('map-id').value,
          name: document.getElementById('payment-form-name').value,
          email: document.getElementById('payment-form-email').value,
        };
        let url =
          'https://z5ac1n46h0.execute-api.us-west-2.amazonaws.com/default/toporace-charge';
        let response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'text/plain' },
          body: JSON.stringify(body),
        });
        if (response.ok) {
          console.log('Purchase Complete!');
          navigate('/complete');
        }
      }
    });
  }

  render() {
    return (
      <Layout>
        <div className="checkout">
          <div className="checkout-overview">
            <h2>Event Poster</h2>
            <h3 style={{ marginBottom: '30px' }}>$25.00</h3>
            <div>
              {/* TODO: move this to a local image */}
              <img
                src="https://d1wqzb5bdbcre6.cloudfront.net/7bdcc698b0f9bb3c0b046c9f19227c9f3b54a7ef/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f666c5f746573745f38726863364b7063346134575437336b6973366a374e4d47"
                alt="Product"
                className="ProductImage-image"
              />
            </div>
          </div>

          <div className="checkout-form">
            <div className="cell payment">
              <form id="payment-form">
                <div id="paymentRequest"></div>

                <fieldset>
                  <legend className="card-only">Pay with card</legend>

                  <div className="row">
                    <div className="field">
                      <label htmlFor="payment-form-name">Name</label>
                      <input
                        id="payment-form-name"
                        className="input"
                        type="text"
                        placeholder="Your Name"
                        required=""
                        autoComplete="name"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="field">
                      <label htmlFor="payment-form-email">Email</label>
                      <input
                        id="payment-form-email"
                        className="input"
                        type="text"
                        placeholder="adventure@example.com"
                        required
                        autoComplete="email"
                      />
                    </div>
                  </div>

                  <div data-locale-reversible>
                    <div className="row">
                      <div className="field">
                        <label htmlFor="payment-form-address">Address</label>
                        <input
                          id="payment-form-address"
                          className="input"
                          type="text"
                          placeholder="123 Topo St"
                          required
                          autoComplete="address-line1"
                        />
                      </div>
                    </div>
                    <div className="row" data-locale-reversible>
                      <div className="field">
                        <label htmlFor="payment-form-city">City</label>
                        <input
                          id="payment-form-city"
                          className="input"
                          type="text"
                          placeholder="Denver"
                          required
                          autoComplete="address-level2"
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="payment-form-state">State</label>
                        <input
                          id="payment-form-state"
                          className="input empty"
                          type="text"
                          placeholder="CO"
                          required
                          autoComplete="address-level1"
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="payment-form-zip">ZIP</label>
                        <input
                          id="payment-form-zip"
                          className="input empty"
                          type="text"
                          placeholder="54321"
                          required
                          autoComplete="postal-code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="field">
                      <label htmlFor="payment-card">Card</label>
                      <div id="payment-card" className="input"></div>
                    </div>
                  </div>

                  <input id="map-id" value={this.state.id} type="hidden" />

                  <button type="submit">Pay $25</button>
                </fieldset>

                <div className="error" role="alert">
                  <span id="card-errors" className="message"></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Checkout;
